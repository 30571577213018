import { FC, memo } from 'react';
import { clientFormStatusVariants } from '../../../models/ClientFormStatus';
import StatusTag, { StatusVariant } from '../../shared/tags/StatusTag';
import useStatusText from '../../../hooks/useStatusText';
import { GQLStatusToPlatformStatus } from '../../../models/TableView';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormStatusRenderer: FC<{ data: string; row: any }> = (props) => {
  const status = GQLStatusToPlatformStatus[props.data];
  const archivedUtc = props.row.archivedUtc;
  const type = props.row.type;
  const requiresApproval = props.row.templateForm.requiresApproval;

  const statusText = useStatusText({
    archivedUtc,
    status,
    type,
    requiresApproval,
  });

  return (
    <StatusTag
      data-cy="form-status-tag"
      text={statusText}
      statusType={archivedUtc ? StatusVariant.RED : clientFormStatusVariants[status]}
      preserveSpace={false}
      align={'left'}
    />
  );
};

export default memo(FormStatusRenderer);
